export default axios => resource => ({
    getStat() {
      return axios.get(`${resource}`)
    },
    mines(wager, bombs) {
      return axios.get(`${resource}/${wager}/${bombs}`)
    },
    minesTake(id) {
      return axios.get(`${resource}/take/${id}`)
    },
    minesMine(id, mineId) {
      return axios.get(`${resource}/mine/${id}/${mineId}`)
    },
    minesMultiplier(bombs) {
      return axios.get(`${resource}/mul/${bombs}`)
    },


    //###############################################

 playGame(slot) {
  console.log("API MINE SLOTS ENVIANDO " +JSON.stringify(slot));
  return axios.post(`/slots/playslots`, { slot });
},

getSlots() {
  // Se providerId for fornecido, adiciona ao corpo da requisição
  //const data = providerId ? { providerId } : {};
  return axios.post(`/slots/getslots`);
},



})