import { render, staticRenderFns } from "./Battle.vue?vue&type=template&id=3f698974&scoped=true"
import script from "./Battle.vue?vue&type=script&lang=js"
export * from "./Battle.vue?vue&type=script&lang=js"
import style0 from "./Battle.vue?vue&type=style&index=0&id=3f698974&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f698974",
  null
  
)

export default component.exports