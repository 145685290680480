<template>
  <div class="slider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/images/banners/fortune-tiger1.png" alt="Banner 1">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/images/banners/bomber.png" alt="Banner 2">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/images/banners/viking.png" alt="Banner 3">
        </div>
        <!-- Adicione mais imagens conforme necessário -->
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';

export default {
  data() {
    return {
      swiper: null, // Instância do Swiper
    };
  },
  mounted() {
    // Usar this.$nextTick() para inicializar o Swiper após a renderização completa do DOM
    this.$nextTick(() => {
      this.initSwiper();
    });
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        loop: true,
        autoplay: {
          delay: 3000, // Intervalo de 3 segundos
        },
        effect: 'slide', // Efeito de animação da direita para a esquerda
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          // Configurações para diferentes tamanhos de tela
          768: {
            slidesPerView: 1, // Número de slides visíveis em telas menores (celulares)
          },
          1024: {
            slidesPerView: 3, // Número de slides visíveis em telas maiores (desktop)
          },
        },
      });
    },
  },
  beforeDestroy() {
    // Destruir o Swiper ao sair do componente para evitar vazamentos de memória
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
};
</script>

<style scoped>
.slider {
  width: 100%;
}

/* Estilos personalizados para o slider */
</style>
