export default axios => resource => ({

  getHistory() {
    return axios.get(`${resource}/history/withdraw`)
  },

  getIncomeHistory() {
    return axios.get(`${resource}/history/income`)
  },

  getPaymentPageSettings() {
    return axios.get(`${resource}/page/settings`)
  },

  getPaymentForm(sum, direction, params) {
    console.log("GET PAYMENT FORM ");
    console.log("GET PAYMENT FORM sum : "+sum);
    console.log("GET PAYMENT FORM direction : "+direction);
    console.log("GET PAYMENT FORM paramns : "+params);
    return axios.post(`${resource}/url`, { sum, direction, params })
  },

  requestWithdraw (sum, direction, params, verificationUUID) {
    return axios.post(`${resource}/withdraw`, { sum, direction, params, verificationUUID })
  }

})