<template>
    <div>
      <h1>Redirecionando...</h1>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      window.location.replace("https://m.pg-redirect.net/126/index.html?l=en&btt=1&ot=49c0e3981bf2ea66df5892bb242f0ef8&ops=49c0e3981bf2ea66df5892bb242f0ef8");
    }
  }
  </script>
  