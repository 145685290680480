

<template>
  <div class="slot-container">
    <div v-for="slot in slots" :key="slot.id" class="slot-item">
      <img :src="slot.sloturlimg" @click="launchGame(slot)" class="game-image">
      <div>{{ slot.slotname }}</div>
    </div>
    <div v-if="errorMessage">{{ errorMessage }}</div>
    <Login v-if="showLogin" @close="showLogin = false" />
  </div>
</template>

<script>
import Login from "../components/modals/Login";
// import Moedas from "../components/confeti.vue"; // Removido por simplificação

export default {
  components: {
    Login,
    // Moedas // Removido por simplificação
  },
  data() {
    return {
      showLogin: false,
      slots: [],
      errorMessage: '' // Adicionado para armazenar mensagens de erro
    }
  },
  mounted() {
    this.fetchSlots(); // Chamada ao montar o componente
  },
  methods: {


    async fetchSlots() { // Adicionado providerId opcional
      console.log("fechSlots ANTE DO TRY ...");
      try {
        const response = await this.$api.mines.getSlots(); // Ajustado para usar o serviço correto
        this.slots = response;

        this.errorMessage = '';
        this.errorMessage = ''; // Limpa a mensagem de erro se a requisição for bem-sucedida
      } catch (error) {
        this.errorMessage = 'Falha ao buscar slots. Por favor, tente novamente mais tarde.';
      }
    },


launchGame(slot) {
  // Verifica diretamente se slot.slotdesc contém uma URL válida
  if (slot.slotdesc && slot.slotdesc.startsWith('http')) {
    console.log("SLOTDESC ", slot.slotdesc);

    window.open(slot.slotdesc, '_blank');
    return; // Interrompe a execução da função após abrir a URL
  }

  // Se não houver uma URL diretamente disponível em slot.slotdesc, faz a chamada API
  this.$api.mines.playGame(slot).then(({ status, data }) => {
      console.log("RESPOSTA", data);
      if (status === 200) {
        // Se a resposta é HTML
        if (typeof data === 'string' && (data.trim().startsWith('<!doctype html>') || data.trim().startsWith('<html>'))) {
          const newWindow = window.open('', '_blank');
          newWindow.document.write(data);
          newWindow.document.close();
        } else {
          alert("Formato de resposta desconhecido.");
        }
      } else {
        alert(`Erro ao iniciar o jogo: ${status}`);
      }
    }).catch(error => {
      console.error("Erro ao iniciar o jogo:", error);
      alert("Jogo temporariamente com problemas. Tente novamente mais tarde.");
    });
}


/*
    launchGame(slot) {
  this.$api.mines.playGame(slot)
    .then(({ status, data }) => { // Desestruturação do objeto de resposta
      console.log("RESPOSTA"+slot.slotdesc);
      if (status === 200) { // Verifica se o status é 200 (OK)
        // Se a resposta é HTML
        if (typeof data === 'string' && (data.startsWith('<!doctype html>') || data.startsWith('<html>'))) {
          const newWindow = window.open('', '_blank');
          newWindow.document.write(data);
          newWindow.document.close();
        }
        // Se a resposta é uma URL  typeof slot.slotdesc === 'string' && 
        else if   (slot.slotdesc) {
          window.open(slot.slotdesc, '_blank');
        } else {
          alert("Formato de resposta desconhecido.");
        }
      } else {
        alert(`Erro ao iniciar o jogo: ${slot.slotdesc} outro ${status}`);
      }
    })
    .catch(error => {
      console.error("Erro ao iniciar o jogo:", error);
      alert("Jogo temporariamente com problemas. Tente novamente mais tarde.");
    });
}

*/



  }
}
</script>

<style>
.game-image {
  border-radius: 10px;
  cursor: pointer;
  margin: 11px;
}
/* Estilos adicionais */

/* Estilos para a área onde os slots serão exibidos */
.slot-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Espaço entre as imagens */
}

/* Estilos para cada item de slot */
.slot-item {
  flex: 1;
  min-width: 150px; /* Largura mínima para cada imagem */
  max-width: 200px; /* Largura máxima para cada imagem */
  text-align: center; /* Centraliza o texto abaixo da imagem */
}

/* Estilos para as imagens */
.game-image {
  width: 100%; /* Faz a imagem preencher o espaço disponível */
  border-radius: 10px; /* Cantos arredondados */
  cursor: pointer;
}

</style>
